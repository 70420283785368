import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/suspect/Documents/coding/dbs-dresden/src/componets/mdxTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <title>Investment und Nachhaltigkeit</title>
    <h1>{`Investment und Nachhaltigkeit`}</h1>
    <p>{`Als Anleger haben wir es in der Hand. Mit unseren Anlageentscheidungen bestimmen wir nicht nur die Rendite unserer Anlagen. Wir können auch dazu beitragen, dass die Welt ein klein wenig besser wird. Wie das geht: Statt in die etablierten Flaggschifffonds der großen Fondsgesellschaften können wir unser Geld auch in Fonds investieren, bei denen Ökologie, Nachhaltigkeit sowie ethische Anlageentscheidungen (z.B. keine Kinderarbeit, keine Rüstung) im Vordergrund stehen. Und das Beste dabei: das muss nicht einmal zu Lasten der Rendite gehen oder zu höheren Kosten führen.`}</p>
    <h1>{`Erklärung zur Nachhaltigkeitsstrategie`}</h1>
    <p>{`Anbieter, die erkennbar keine Strategie zur Einbeziehung von Nachhaltigkeitsrisiken in ihre Investitionsentscheidungen haben, beziehe ich je nach Kundenwunsch nicht in meine Empfehlungen ein. Im Rahmen der im Kundeninteresse erfolgenden individuellen Beratung stelle ich gesondert dar, wenn die Berücksichtigung der Nachhaltigkeitsrisiken bei der Investmententscheidung für mich erkennbare Vor- bzw. Nachteile für den individuellen Kunden bedeuten.`}</p>
    <p>{`Über die jeweilige Berücksichtigung von Nachhaltigkeitsrisiken bei Investitionsentscheidungen des jeweiligen Anbieters informiert dieser mit seinen vorvertraglichen Informationen. Bei Fragen dazu kann der Kunde mich gerne im Vorfeld eines möglichen Abschlusses ansprechen.`}</p>
    <p>{`Im Rahmen der Beratung werden die wichtigsten nachteiligen Auswirkungen von Investitionsentscheidungen auf Nachhaltigkeitsfaktoren der Anbieter berücksichtigt. Die Berücksichtigung erfolgt auf Basis der von den Anbietern zur Verfügung gestellten Informationen. Für deren Richtigkeit bin ich jedoch nicht verantwortlich.`}</p>
    <p>{`Die Vergütung für die Vermittlung von Finanzprodukten wird nicht von den jeweiligen Nachhaltigkeitsrisiken beeinflusst.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      