/** @jsx jsx */
import {Box, jsx, Link, Flex, Grid, Image, Heading, NavLink} from 'theme-ui'
import * as React from "react";

import logo from "../images/logo.png"

const headLinks = [
    {
        url: "/felder",
        text: "Geschäftsfelder"
    },
    {
        url: "/kontakt",
        text: "Kontakt"
    },
    {
        url: "/nachhaltigkeit",
        text: "Nachhaltigkeit"
    },
    {
        url: "/impressum",
        text: "Impressum"
    },
    {
        url: "/datenshutz",
        text: "Datenschutzerklärung"
    },

]


const template = (props) => {

    return (
    <div
        sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }}>
        <header
            sx={{
                position: "sticky",
                top: "0",
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                backgroundColor:"rgb(238,244,216)",
                zIndex: "100",
            }}>

                <Flex as='nav' sx={{
                    flexWrap: "wrap"
                }}>
                    <NavLink href="/" p={2}>
                        DBS Dresden
                    </NavLink>
                    {headLinks.map(link => (
                        <NavLink href={link.url} p={2}>
                            {link.text}
                        </NavLink>
                    ))}
                </Flex>

        </header>
        <main
            sx={{
                width: '100%',
                flex: '1 1 auto',
            }}>
            <div
                sx={{
                    maxWidth: "1000px",
                    mx: 'auto',
                    p:2
                }}>
                {props.children}
            </div>
        </main>
    </div>
    )}
export default  template;